export const login = (state = { userRole: "", products: [] }, action) => {
	switch (action.type) {
		case "LOGIN_WITH_EMAIL": {
			return { ...state, values: action.payload }
		}
		case "LOGOUT": {
			return { ...state, values: undefined, userRole: "", products: [] }
		}
		case "CHANGE_ROLE": {
			return { ...state, userRole: action.userRole }
		}
		case "PRODUCTS": {
			return { ...state, products: action.products }
		}
		default: {
			return state
		}
	}
}
