import React from "react";
import {connect} from "react-redux";
import {Navbar} from "reactstrap";
import classnames from "classnames";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";
import URLS from "../../../utils/urls";

const ThemeNavbar = (props) => {
	const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
	const navbarTypes = ["floating", "static", "sticky", "hidden"];

	let showEnvWarning = true;
	const environment = process.env.REACT_APP_NODE_ENV || 'unknown environment';
	if (environment === 'production') {
		showEnvWarning = false;
	}

	return (
		<React.Fragment>
			<div className="content-overlay"/>
			<div className="header-navbar-shadow"/>
			<Navbar
				className={classnames(
					"header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
					{
						"navbar-light":
							props.navbarColor === "default" ||
							!colorsArr.includes(props.navbarColor),
						"navbar-dark": colorsArr.includes(props.navbarColor),
						"bg-primary":
							props.navbarColor === "primary" && props.navbarType !== "static",
						"bg-danger":
							props.navbarColor === "danger" && props.navbarType !== "static",
						"bg-success":
							props.navbarColor === "success" && props.navbarType !== "static",
						"bg-info": props.navbarColor === "info" && props.navbarType !== "static",
						"bg-warning":
							props.navbarColor === "warning" && props.navbarType !== "static",
						"bg-dark": props.navbarColor === "dark" && props.navbarType !== "static",
						"d-none": props.navbarType === "hidden" && !props.horizontal,
						"floating-nav":
							(props.navbarType === "floating" && !props.horizontal) ||
							(!navbarTypes.includes(props.navbarType) && !props.horizontal),
						"navbar-static-top": props.navbarType === "static" && !props.horizontal,
						"fixed-top": props.navbarType === "sticky" || props.horizontal,
						scrolling: props.horizontal && props.scrolling,
					},
				)}>
				<div className="navbar-wrapper">
					<div className="navbar-container content">
						<div
							className="navbar-collapse d-flex justify-content-between align-items-center"
							id="navbar-mobile">
							<div className="bookmark-wrapper">
								<NavbarBookmarks
									sidebarVisibility={props.sidebarVisibility}
									handleAppOverlay={props.handleAppOverlay}
								/>
							</div>
							{showEnvWarning &&
								<div
									className="text-danger font-weight-bold font-small-1">

									<div>This is a {environment} environment, ONLY used by Simdustry authorized staff
										for testing purposes.
									</div>
									<div>Please visit <a
										href={'https://simdustrydigital.com'}>https://simdustrydigital.com</a> for the
										latest stable version.
									</div>
								</div>
							}
							<NavbarUser
								handleAppOverlay={props.handleAppOverlay}
								changeCurrentLang={props.changeCurrentLang}
								userName={props.user?.firstName + " " + props.user?.lastName}
								userImg={
									props.user?.picture
										? URLS.backendURL + props.user.picture
										: userImg
								}
							/>
						</div>
					</div>
				</div>
			</Navbar>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.auth.login.values,
	};
};
export default connect(mapStateToProps)(ThemeNavbar);
