import { history } from "../../../history";

export const changeRole = (role, redirect) => {
	return dispatch => {
		dispatch({ type: "CHANGE_ROLE", userRole: role })
		if (redirect)
			history.push('/')
	}
}
export const loginData = data => {
	return dispatch => dispatch({ type: "LOGIN_WITH_EMAIL", payload: data })
}
export const logout = () => {
	return dispatch => {
		dispatch({ type: "LOGOUT" })
		history.push("/login")
		//window.location.reload()
	}
}

export const userProducts = (products) => {
	return dispatch => dispatch({ type: "PRODUCTS", products: products })
}
