import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
	{
		id: "home",
		title: "Home",
		type: "item",
		icon: <Icon.Home size={20} />,
		permissions: ["SuperAdmin", "Admin", "Facilitator"],
		navLink: "/",
	},
	{
		id: "users",
		title: "Users",
		type: "item",
		icon: <Icon.Users size={20} />,
		permissions: ["SuperAdmin", "Admin"],
		navLink: "/user/list",
	},
	{
		id: "partners",
		title: "Partners",
		type: "item",
		icon: <Icon.Layers size={20} />,
		permissions: ["SuperAdmin"],
		navLink: "/partner/list",
	},
	{
		id: "companies",
		title: "Company",
		type: "item",
		icon: <Icon.Briefcase size={20} />,
		permissions: ["SuperAdmin", "Admin", "Facilitator"],
		navLink: "/company/list",
	},
	{
		id: "Session",
		title: "Sessions",
		type: "item",
		icon: <Icon.List size={20} />,
		permissions: ["SuperAdmin", "Admin", "Facilitator"],
		navLink: "/session/list",
	},
	{
		type: "groupHeader",
		groupTitle: "Customization",
		permissions: ["SuperAdmin", "Admin"],
	},
	{
		id: "simtalent",
		title: "Simtalent",
		type: "collapse",
		product: "Simtalent",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "character_groups",
				title: "Character Profiles",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent",
				navLink: "/character-group/list",
			},
			/*{
				id: "character_profiles",
				title: "Character Profile",
				type: "item",
				icon: <Icon.Anchor size={20} />,
				permissions: ["SuperAdmin", "Admin"],
				product: "Simtalent",
				navLink: "/character-profile/list",
			},*/
			{
				id: "client_groups",
				title: <span style={{ fontSize: "10px" }}>Development-Options & Events</span>,
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent",
				navLink: "/course-group/list",
			},
			{
				id: "SimtalentLocalization",
				title: "Translation",
				type: "item",
				icon: <Icon.Globe size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent",
				navLink: "/languages/simtalent/list",
			},
			{
				id: "Grid Boxes",
				title: "Grid Boxes",
				type: "item",
				icon: <Icon.Grid size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent",
				navLink: "/grid-boxes/list",
			},
			{
				id: "SimtalentStartingPositionGroups",
				title: "Starting position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent",
				navLink: "/products/simtalent/startingPositionGroups/index",
			},
		],
	},
	{
		id: "simtalent-demo",
		title: "Simtalent Demo",
		type: "collapse",
		product: "Simtalent Demo",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "demo_character_groups",
				title: "Character Profiles",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent",
				navLink: "/products/simtalent/demo/character-groups/index",
			},
			{
				id: "client_groups",
				title: "Development Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent Demo",
				navLink: "/products/simtalent/demo/development-groups/index",
			},
			{
				id: "Grid Boxes",
				title: "Grid Boxes",
				type: "item",
				icon: <Icon.Grid size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simtalent Demo",
				navLink: "/products/simtalent/demo/grid-boxes/index",
			},
		],
	},
	{
		id: "simflex",
		title: "Simflex",
		type: "collapse",
		product: "Simflex",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "SimflexChecklistDefaultGroups",
				title: "Checklist Default Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simflex",
				navLink: "/products/simflex/checklistDefaultGroups/index",
			},
			{
				id: "SimflexTranslation",
				title: "Translation",
				type: "item",
				icon: <Icon.Grid size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simflex",
				navLink: "/products/simflex/translations/languages/index", //"/languages/simflex/list",
			},
		],
	},
	{
		id: "managing3",
		title: "Managing3",
		type: "collapse",
		product: "Managing3",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "Managing3EventGroup",
				title: "Event Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Managing3",
				navLink: "/products/managing3/eventGroups/index",
			},
			{
				id: "Managing3ChecklistDefaultGroups",
				title: "Checklist Default Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Managing3",
				navLink: "/products/managing3/checklistDefaultGroups/index",
			},
			{
				id: "Managing3Translation",
				title: "Translation",
				type: "item",
				icon: <Icon.Grid size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Managing3",
				navLink: "/products/managing3/translations/languages/index",
			},
		],
	},
	{
		id: "simteam",
		title: "Simteam",
		type: "collapse",
		product: "Simteam",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "SimteamLocalization",
				title: "Translation",
				type: "item",
				icon: <Icon.Globe size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simteam",
				navLink: "/products/simteam/translations/languages/index",
			},
			{
				id: "SimteamEventGroup",
				title: "Event Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simteam",
				navLink: "/products/simteam/eventGroups/index",
			},
			{
				id: "SimteamCustomerCardGroups",
				title: "Customer Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simteam",
				navLink: "/products/simteam/customerCardGroups/index",
			},
			{
				id: "SimteamDefaultValueGroups",
				title: "Starting position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simteam",
				navLink: "/products/simteam/defaultValueGroups/index",
			},
			{
				id: "SimteamAdditionalValueGroups",
				title: "Additional position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simteam",
				navLink: "/products/simteam/additionalValueGroups/index",
			},
			{
				id: "SimteamImages",
				title: "Images",
				type: "item",
				icon: <Icon.Image size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simteam",
				navLink: "/products/simteam/images/index",
			},
		],
	},
	{
		id: "simremote",
		title: "Simremote",
		type: "collapse",
		product: "Simremote",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "SimremoteLocalization",
				title: "Translation",
				type: "item",
				icon: <Icon.Globe size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simremote",
				navLink: "/products/simremote/translations/languages/index",
			},
			{
				id: "SimremoteEventGroup",
				title: "Event Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simremote",
				navLink: "/products/simremote/eventGroups/index",
			},
			{
				id: "SimremoteCustomerCardGroups",
				title: "Customer Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simremote",
				navLink: "/products/simremote/customerCardGroups/index",
			},
			{
				id: "SimremoteDefaultValueGroups",
				title: "Starting position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simremote",
				navLink: "/products/simremote/defaultValueGroups/index",
			},
			{
				id: "SimremoteAdditionalValueGroups",
				title: "Additional position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simremote",
				navLink: "/products/simremote/additionalValueGroups/index",
			},
			{
				id: "SimremoteImages",
				title: "Images",
				type: "item",
				icon: <Icon.Image size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simremote",
				navLink: "/products/simremote/images/index",
			},
		],
	},
	{
		id: "simintercultural",
		title: "Simintercultural",
		type: "collapse",
		product: "Simintercultural",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "SiminterculturalLocalization",
				title: "Translation",
				type: "item",
				icon: <Icon.Globe size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simintercultural",
				navLink: "/products/simintercultural/translations/languages/index",
			},
			{
				id: "SiminterculturalEventGroup",
				title: "Event Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simintercultural",
				navLink: "/products/simintercultural/eventGroups/index",
			},
			{
				id: "SiminterculturalCustomerCardGroups",
				title: "Customer Groups",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simintercultural",
				navLink: "/products/simintercultural/customerCardGroups/index",
			},
			{
				id: "SiminterculturalDefaultValueGroups",
				title: "Starting position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simintercultural",
				navLink: "/products/simintercultural/defaultValueGroups/index",
			},
			{
				id: "SiminterculturalAdditionalValueGroups",
				title: "Additional position values",
				type: "item",
				icon: <Icon.Database size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simintercultural",
				navLink: "/products/simintercultural/additionalValueGroups/index",
			},
			{
				id: "SiminterculturalImages",
				title: "Images",
				type: "item",
				icon: <Icon.Image size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simintercultural",
				navLink: "/products/simintercultural/images/index",
			},
		],
	},
	{
		id: "simservice",
		title: "Simservice",
		type: "collapse",
		product: "Simservice",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "SimserviceChecklistGroups",
				title: "Checklist Groups",
				type: "item",
				icon: <Icon.List size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simservice",
				navLink: "/products/simservice/checklistGroups/index",
			},
			{
				id: "SimserviceTranslation",
				title: "Translation",
				type: "item",
				icon: <Icon.Globe size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simservice",
				navLink: "/products/simservice/translations/languages/index",
			},
		],
	},
	{
		id: "simcompact",
		title: "Simcompact",
		type: "collapse",
		product: "Simcompact",
		icon: <Icon.Clipboard size={20} />,
		children: [
			{
				id: "SimcompactChecklistGroups",
				title: "Checklist Groups",
				type: "item",
				icon: <Icon.List size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simcompact",
				navLink: "/products/simcompact/checklistGroups/index",
			},
			{
				id: "SimcompactTranslation",
				title: "Translation",
				type: "item",
				icon: <Icon.Globe size={20} />,
				permissions: ["SuperAdmin", "Admin", "Facilitator"],
				product: "Simcompact",
				navLink: "/products/simcompact/translations/languages/index",
			}
		],
	},
	// {
	// 	id: "simremote",
	// 	title: "Simremote",
	// 	type: "collapse",
	// 	product: "Simremote",
	// 	icon: <Icon.Clipboard size={20} />,
	// 	children: [
	// 		{
	// 			id: "SimremoteCommonEvents",
	// 			title: "Common Events",
	// 			type: "item",
	// 			icon: <Icon.Grid size={20} />,
	// 			permissions: ["SuperAdmin"],
	// 			product: "Simremote",
	// 			navLink: "/simremote/common-events/list",
	// 		},
	// 		{
	// 			id: "SimremoteCharacterProfiles",
	// 			title: "Character Profiles",
	// 			type: "item",
	// 			icon: <Icon.Grid size={20} />,
	// 			permissions: ["SuperAdmin"],
	// 			product: "Simremote",
	// 			navLink: "/simremote/character-profiles/list",
	// 		},
	// 		{
	// 			id: "Customer_Card_Group",
	// 			title: "Customer Card Group",
	// 			type: "item",
	// 			icon: <Icon.List size={20} />,
	// 			permissions: ["SuperAdmin", "Admin", "Facilitator"],
	// 			navLink: "/simremote/customer-card-group/list",
	// 		},
	// 		{
	// 			id: "SimremoteLocalization",
	// 			title: "Translation",
	// 			type: "item",
	// 			icon: <Icon.Globe size={20} />,
	// 			permissions: ["SuperAdmin", "Admin", "Facilitator"],
	// 			product: "Simremote",
	// 			navLink: "/languages/simremote/list",
	// 		},
	// 	],
	// },
];

export default navigationConfig;
