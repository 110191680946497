import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import "./@fake-db";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<Spinner />}>
			<Layout>
				<LazyApp />
			</Layout>
		</Suspense>
	</Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
