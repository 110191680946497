import Axios from "axios";
import { makeUseAxios } from "axios-hooks";
import URLs from "../utils/urls";

const axios = Axios.create({
	baseURL: URLs.backendURL,
	headers: {
		Accept: "application/json",
		withCredentials: true,
	},
});

export const setClientToken = (token) => {
	axios.defaults.headers.common["Authorization"] = token;
};

export const clearClientToken = () => {
	axios.defaults.headers.common["Authorization"] = "";
};

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response) {
			if (error.response.status === 400) {
				const { message: errors } = error.response.data;
				const validationErrors = errors?.map((error) => ({
					field: error.path,
					message: error.message,
				}));
				const errorsAll = { ...error, validationErrors };
				return Promise.reject(errorsAll);
			}
		}
		return Promise.reject(error);
	},
);

const useAxios = makeUseAxios({
	axios,
	cache: false,
});

export default {
	axios,
	useAxios,
};
